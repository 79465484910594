import _safeBuffer from "safe-buffer";
import _dataStream from "./data-stream";
import _jwa from "jwa";
import _stream from "stream";
import _tostring from "./tostring";
import _util from "util";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*global module*/
var Buffer = _safeBuffer.Buffer;
var DataStream = _dataStream;
var jwa = _jwa;
var Stream = _stream;
var toString = _tostring;
var util = _util;
var JWS_REGEX = /^[a-zA-Z0-9\-_]+?\.[a-zA-Z0-9\-_]+?\.([a-zA-Z0-9\-_]+)?$/;

function isObject(thing) {
  return Object.prototype.toString.call(thing) === "[object Object]";
}

function safeJsonParse(thing) {
  if (isObject(thing)) return thing;

  try {
    return JSON.parse(thing);
  } catch (e) {
    return undefined;
  }
}

function headerFromJWS(jwsSig) {
  var encodedHeader = jwsSig.split(".", 1)[0];
  return safeJsonParse(Buffer.from(encodedHeader, "base64").toString("binary"));
}

function securedInputFromJWS(jwsSig) {
  return jwsSig.split(".", 2).join(".");
}

function signatureFromJWS(jwsSig) {
  return jwsSig.split(".")[2];
}

function payloadFromJWS(jwsSig, encoding) {
  encoding = encoding || "utf8";
  var payload = jwsSig.split(".")[1];
  return Buffer.from(payload, "base64").toString(encoding);
}

function isValidJws(string) {
  return JWS_REGEX.test(string) && !!headerFromJWS(string);
}

function jwsVerify(jwsSig, algorithm, secretOrKey) {
  if (!algorithm) {
    var err = new Error("Missing algorithm parameter for jws.verify");
    err.code = "MISSING_ALGORITHM";
    throw err;
  }

  jwsSig = toString(jwsSig);
  var signature = signatureFromJWS(jwsSig);
  var securedInput = securedInputFromJWS(jwsSig);
  var algo = jwa(algorithm);
  return algo.verify(securedInput, signature, secretOrKey);
}

function jwsDecode(jwsSig, opts) {
  opts = opts || {};
  jwsSig = toString(jwsSig);
  if (!isValidJws(jwsSig)) return null;
  var header = headerFromJWS(jwsSig);
  if (!header) return null;
  var payload = payloadFromJWS(jwsSig);
  if (header.typ === "JWT" || opts.json) payload = JSON.parse(payload, opts.encoding);
  return {
    header: header,
    payload: payload,
    signature: signatureFromJWS(jwsSig)
  };
}

function VerifyStream(opts) {
  opts = opts || {};
  var secretOrKey = opts.secret || opts.publicKey || opts.key;
  var secretStream = new DataStream(secretOrKey);
  (this || _global).readable = true;
  (this || _global).algorithm = opts.algorithm;
  (this || _global).encoding = opts.encoding;
  (this || _global).secret = (this || _global).publicKey = (this || _global).key = secretStream;
  (this || _global).signature = new DataStream(opts.signature);

  (this || _global).secret.once("close", function () {
    if (!(this || _global).signature.writable && (this || _global).readable) this.verify();
  }.bind(this || _global));

  (this || _global).signature.once("close", function () {
    if (!(this || _global).secret.writable && (this || _global).readable) this.verify();
  }.bind(this || _global));
}

util.inherits(VerifyStream, Stream);

VerifyStream.prototype.verify = function verify() {
  try {
    var valid = jwsVerify((this || _global).signature.buffer, (this || _global).algorithm, (this || _global).key.buffer);
    var obj = jwsDecode((this || _global).signature.buffer, (this || _global).encoding);
    this.emit("done", valid, obj);
    this.emit("data", valid);
    this.emit("end");
    (this || _global).readable = false;
    return valid;
  } catch (e) {
    (this || _global).readable = false;
    this.emit("error", e);
    this.emit("close");
  }
};

VerifyStream.decode = jwsDecode;
VerifyStream.isValid = isValidJws;
VerifyStream.verify = jwsVerify;
exports = VerifyStream;
export default exports;