import _signStream from "./lib/sign-stream";
import _verifyStream from "./lib/verify-stream";
var exports = {};

/*global exports*/
var SignStream = _signStream;
var VerifyStream = _verifyStream;
var ALGORITHMS = ["HS256", "HS384", "HS512", "RS256", "RS384", "RS512", "PS256", "PS384", "PS512", "ES256", "ES384", "ES512"];
exports.ALGORITHMS = ALGORITHMS;
exports.sign = SignStream.sign;
exports.verify = VerifyStream.verify;
exports.decode = VerifyStream.decode;
exports.isValid = VerifyStream.isValid;

exports.createSign = function createSign(opts) {
  return new SignStream(opts);
};

exports.createVerify = function createVerify(opts) {
  return new VerifyStream(opts);
};

export default exports;
export const sign = exports.sign,
      verify = exports.verify,
      decode = exports.decode,
      isValid = exports.isValid,
      createSign = exports.createSign,
      createVerify = exports.createVerify;
const _ALGORITHMS = exports.ALGORITHMS;
export { _ALGORITHMS as ALGORITHMS };